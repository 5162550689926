// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useState } from 'react';
import { Box, Button, Modal, SpaceBetween, TopNavigation } from '@awsui/components-react';
import { Auth, Logger } from 'aws-amplify';

import useAppContext from '../../contexts/app';

const logger = new Logger('TopNavigation');

/* eslint-disable react/prop-types */
const SignOutModal = ({ visible, setVisible }) => {
  async function signOut() {
    try {
      await Auth.signOut();
      logger.debug('signed out');
      window.location.reload();
    } catch (error) {
      logger.error('error signing out: ', error);
    }
  }
  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => signOut()}>
              Sign Out
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Sign Out"
    >
      Sign out of the application?
    </Modal>
  );
};

const CallAnalyticsTopNavigation = () => {
  const { user } = useAppContext();
  const userId = user?.attributes?.email || 'user';
  const [isSignOutModalVisible, setIsSignOutModalVisiblesetVisible] = useState(false);
  return (
    <>
      <div
        id="top-navigation"
        style={{ position: 'sticky', top: 0, zIndex: 1002, display: 'flex', alignItems: 'center' }}
      >
        <img
          src="/averta-logo.png"
          alt="Averta Logo"
          style={{ width: '100px', height: 'auto', margin: '10px' }}
        />
        <div style={{ flexGrow: 2 }}>
          <TopNavigation
            identity={{ href: '#', title: 'averta Real-time Call Analytics' }}
            i18nStrings={{ overflowMenuTriggerText: 'More' }}
            utilities={[
              {
                type: 'menu-dropdown',
                text: userId,
                description: userId,
                iconName: 'user-profile',
                items: [
                  {
                    id: 'signout',
                    type: 'button',
                    text: (
                      <Button
                        variant="primary"
                        onClick={() => setIsSignOutModalVisiblesetVisible(true)}
                      >
                        Sign out
                      </Button>
                    ),
                  },
                  {
                    id: 'support-group',
                    text: 'Resources',
                    items: [
                      {
                        id: 'documentation',
                        text: 'Website',
                        href: 'https://www.averta.ai',
                        external: true,
                        externalIconAriaLabel: ' (opens in new tab)',
                      },
                      {
                        id: 'source',
                        text: 'Explainer Video',
                        href: 'https://youtu.be/2Z3Q3Z2QDQ0',
                        external: true,
                        externalIconAriaLabel: ' (opens in new tab)',
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
      <SignOutModal
        visible={isSignOutModalVisible}
        setVisible={setIsSignOutModalVisiblesetVisible}
      />
    </>
  );
};

export default CallAnalyticsTopNavigation;
